@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  --primary-color: #2fce65;
  --secondary-color: #e5ffee;
  --dark-primary: #238845;
  --disable-color: #b0e9c3;
}
body {
  font-family: "Poppins", sans-serif !important;
}

.font-pangea {
  font-family: "Pangea", sans-serif;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animate-marquee {
  display: flex;
  width: 200%;
  animation: marquee 20s linear infinite;
}

:where(.css-dev-only-do-not-override-17sses9).ant-layout
  .ant-layout-sider-zero-width-trigger {
  background: white;
  color: #000;
}

:where(.css-dev-only-do-not-override-17sses9).ant-menu-inline .ant-menu-item {
  margin-bottom: 10px;
}

.ant-layout-sider-zero-width-trigger-left {
  top: 16px !important;
}

.ant-layout-sider-below {
  width: 240px !important;
  flex: 0 0 240px !important;
  max-width: 240px !important;
  min-width: 240px !important;
}
.ant-layout-sider-dark {
  width: 240px !important;
  flex: 0 0 240px !important;
  max-width: 240px !important;
  min-width: 240px !important;
}

.ant-layout-sider-zero-width {
  width: 0px !important;
  flex: 0 0 0px !important;
  max-width: 0px !important;
  min-width: 0px !important;
}

.ant-menu-item-selected {
  margin-inline: 0px !important;
  width: calc(100% - 0px) !important;
  padding-left: 24px;
  font-weight: 600;
  border-radius: 8px !important;
  background-color: var(--secondary-color) !important;
  color: var(--primary-color) !important;
}

:where(.css-dev-only-do-not-override-17sses9).ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-17sses9).ant-table-wrapper
  .ant-table-thead
  > tr
  > td {
  background: var(--secondary-color) !important;
}
.ant-menu {
  padding: 12px;
  color: #7c7c7c;
}

.ant-table {
  box-shadow: 0 0 5px #7c7c7c;
}

.ant-table-row:hover {
  background-color: var(--secondary-color) !important;
  color: var(--primary-color) !important;
}
.ant-table-measure-row:hover {
  background-color: var(--secondary-color) !important;
  color: var(--primary-color) !important;
}

.ant-table-column-has-sorters {
  background: var(--secondary-color) !important;
}

.ant-modal-footer {
  display: none !important;
}

.ant-modal-title {
  margin: 0 !important;
  color: white !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
  word-wrap: break-word !important;
  padding: 14px !important;
}

:where(.css-dev-only-do-not-override-17sses9).ant-modal .ant-modal-content {
  padding: 0 !important;
}
.ant-modal-content {
  padding: 0px !important;
}

:where(.css-dev-only-do-not-override-17sses9).ant-modal .ant-modal-body {
  padding: 0px 20px 20px 20px !important;
}

:where(.css-dev-only-do-not-override-17sses9).ant-modal .ant-modal-header {
  background: var(--primary-color) !important;
  color: white !important;
}
:where(.css-dev-only-do-not-override-17sses9).ant-modal .ant-modal-close {
  color: #fff;
}
:where(.css-dev-only-do-not-override-17sses9).ant-tabs .ant-tabs-ink-bar {
  background: var(--primary-color) !important;
}
:where(.css-dev-only-do-not-override-17sses9).ant-tabs
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: var(--primary-color) !important;
}
:where(.css-dev-only-do-not-override-17sses9).ant-tabs .ant-tabs-tab:hover {
  color: var(--secondary-color) !important;
}
:where(.css-dev-only-do-not-override-17sses9).ant-pagination
  .ant-pagination-item-active:hover
  a {
  color: var(--secondary-color) !important;
}
:where(.css-dev-only-do-not-override-17sses9).ant-pagination
  .ant-pagination-item-active
  a {
  color: var(--primary-color) !important;
  font-weight: 600 !important;
}
:where(.css-dev-only-do-not-override-17sses9).ant-pagination
  .ant-pagination-item-active {
  border-color: var(--primary-color) !important;
  border-width: 1.5px !important;
}
:where(.css-dev-only-do-not-override-17sses9).ant-spin .ant-spin-dot-item {
  background-color: var(--primary-color) !important;
}
.custom-radio-group .ant-radio-checked .ant-radio-inner {
  border-color: var(--primary-color) !important;
  background-color: var(--primary-color) !important;
}

.custom-radio-group .ant-radio:hover .ant-radio-inner {
  border-color: var(--primary-color) !important;
}
.ant-modal-body {
  padding: 20px !important;
}

.ant-modal-header {
  background: var(--primary-color) !important;
  color: white !important;
}

.MuiPaper-root .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}
.MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}
.MuiPaper-root .css-1km1ehz.Mui-selected {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}
