.mle_text_area > textarea {
  font-family: "Poppins";
}
.mle_text_area > .ant-input-suffix {
  transform: translateY(4px);
}

.mle_phone_input > .react-international-phone-input {
  flex: 1;
  min-height: 40px;
  font-family: "Poppins";
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid #ccc;
  border-radius: 0px;
}
.mle_phone_input
  .react-international-phone-country-selector
  .react-international-phone-country-selector-button {
  min-height: 40px;
  border-bottom: 2px solid #ccc;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0px;
}
